

interface Grupo {
  nomeGrupo: string,
  codGrupo: number,
  totalConsultas: number
}

import { defineComponent, onMounted, Ref, ref, watch } from "vue"
import Title from "@/components/Title.vue";
import Button from "@/components/Button.vue";
import Api from "@/services/Api";
import { useRouter } from "vue-router";
import ptBr from 'element-plus/lib/locale/lang/pt-br';


export default defineComponent({
  components: { Title, Button },
  name: "RelatorioConsultas",
  setup() {

    const auxModal: Ref<any> = ref('');
    const router = useRouter();
    const idLoja: Ref<any> = ref(null);
    const paginasNumeradas: Ref<any> = ref([]);
    const filteredProducts: Ref<any> = ref([]);
    const inputFind: Ref<string> = ref('');
    const produto: Ref<any> = ref({})
    const openFind: Ref<boolean> = ref(false)
    const findActive: Ref<boolean> = ref(false)
    const totalBuscas: Ref<number> = ref(0)
    const retornoBusca: Ref<any> = ref(null)
    const dataInput: Ref<string> = ref(new Date().toDateString());
    const isLoadingConsultaGrupos: Ref<boolean> = ref(false);
    const isLoadingLojasConsultaGrupos: Ref<boolean> = ref(false);
    const isLoadingGrupos: Ref<boolean> = ref(false);
    const totalConsultasGrupo: Ref<number | null> = ref(null)
    const totalConsultasLoja: Ref<number | null> = ref(null)
    const codGrupo: Ref<number | null> = ref(null)
    const storeGroups: Ref<any[]> = ref([]);
    const dataTable: Ref<any[]> = ref([]);
    

    const seriesLojasConsultas: Ref<any> = ref([]);
      const chartOptionsLojasConsultas = ref({
      chart: { 
        type: "pie" ,
      },
      labels: [],
      legend: { 
        show: true,
        formatter: function(seriesName) {
          const maxLabelLength = 16;
          if (seriesName.length > maxLabelLength) {
            return seriesName.substring(0, maxLabelLength) + '...';
          }
          return seriesName;
        }
       },
      stroke: {
        width: 0,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    });

    const seriesGruposConsultas: Ref<any> = ref([]);
    const chartOptionsGruposConsultas = ref({
    chart: { 
      type: "donut",
     },
    labels: [],
    legend: { 
      show: true,
      formatter: function(seriesName) {
          const maxLabelLength = 16;
          if (seriesName.length > maxLabelLength) {
            return seriesName.substring(0, maxLabelLength) + '...';
          }
          return seriesName;
        } 
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    stroke: {
      width: 0,
    },
  });

    async function getRelatorioConsultas() {
      try {
        const month = new Date(dataInput.value).getMonth() + 1
        const year = new Date(dataInput.value).getFullYear()

        isLoadingConsultaGrupos.value = true
        const { data, error } = await Api.get("relatorio/getRelatorioConsultas", { month, year})

        if(error){
          console.error("Erro ao buscar dados!")
          return;
        }
        //SOMA DO TOTAL DE CONSULTAS DE CADA GRUPO PARA PEGAR O TOTAL GERAL DE CONSULTAS
        const total: number = data.reduce((accumulator: number, grupo: Grupo) => accumulator + grupo.totalConsultas, 0);
        totalConsultasGrupo.value = total

        let series = [] as any
        let chartlabels = [] as any
       
        data.forEach((grupo: Grupo) => {
          series.push(grupo.totalConsultas)
          chartlabels.push(grupo.nomeGrupo)
        });

        chartOptionsGruposConsultas.value.labels = chartlabels
        seriesGruposConsultas.value = series
      } catch (error) {
        console.log(error);
      }finally{
        isLoadingConsultaGrupos.value = false
      }
    }

    async function getRelatorioConsultasPorLojas(grupo: number | null = null) {
      try {
        if(grupo){
          codGrupo.value = grupo
        }
        const month = new Date(dataInput.value).getMonth() + 1
        const year = new Date(dataInput.value).getFullYear()
        
        isLoadingLojasConsultaGrupos.value = true
        const { data, error } = await Api.get("relatorio/getRelatorioConsultasPorLojas", { month, year, codGrupo: codGrupo.value})

        if(error){
          console.error("Erro ao buscar dados!")
          return;
        }
        //SOMA DO TOTAL DE CONSULTAS DE CADA LOJA PARA PEGAR O TOTAL GERAL DE CONSULTAS
        const total: number = data.data.reduce((accumulator: number, item) => accumulator + item.totalConsultas, 0);
        totalConsultasLoja.value = total

        let series = [] as any
        let chartlabels = [] as any
       
        data.data.forEach((loja) => {
          series.push(loja.totalConsultas)
          chartlabels.push(loja.nomeFantasia)
        });

        dataTable.value = data.dataForTable

        chartOptionsLojasConsultas.value.labels = chartlabels
        seriesLojasConsultas.value = series
      } catch (error) {
        console.log(error);
      }finally{
        isLoadingLojasConsultaGrupos.value = false
      }
    }

    async function getStoreGroups() {
      try{
        isLoadingGrupos.value = true
        const { data, error } = await Api.get("getAllStoreGroup");
        storeGroups.value = data.grupos;
        if(error){
          console.log(error)
          return
        }

      }catch(error){
        console.log(error)
      }finally{
        isLoadingGrupos.value = false
      }
    }

    
    async function buscarLoja(cnpj: string | null = null) {
      router.push({ name: "RelatorioConsultasLoja", params: {dataInput:dataInput.value, cnpj: cnpj, codGrupo: codGrupo.value}});
    }

    watch(() => dataInput.value,
      () => {
        getRelatorioConsultas()
        getRelatorioConsultasPorLojas()
      })

    watch(() => codGrupo.value,
      () => {
        getRelatorioConsultasPorLojas()
      })

    onMounted(() => {
      getStoreGroups()
      getRelatorioConsultas()
      getRelatorioConsultasPorLojas(82)
    })

    return {
      router,
      totalConsultasGrupo,
      paginasNumeradas,
      inputFind,
      filteredProducts,
      produto,
      getRelatorioConsultas,
      auxModal,
      idLoja,
      openFind,
      totalBuscas,
      findActive,
      retornoBusca,
      chartOptionsGruposConsultas,
      seriesGruposConsultas,
      dataInput,
      ptBr,
      isLoadingConsultaGrupos,
      seriesLojasConsultas,
      buscarLoja,
      isLoadingLojasConsultaGrupos,
      chartOptionsLojasConsultas,
      totalConsultasLoja,
      storeGroups,
      codGrupo,
      dataTable,
      isLoadingGrupos
    };
  },
})
